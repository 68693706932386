<template>
    <div class="components-catalog panel" v-if="components">
        <panel-legend/>
        <div class="custom-scrollbar">
            <draggable v-model="components" class="drag-drop-elements" :options="dragOptions">
                <div  v-for="(component, index) in components"  :key="index"  
                    class="components-placeholder column">
                    <img :src="src(component.icon)"/>
                    <span>{{$t(component.longname)}}</span>
                </div>
            </draggable>
        </div>
    </div>
</template>
<script>
import draggable from 'vuedraggable'
import PanelLegend from '@/components/BackOffice/PanelLegend.vue'
import questionTemplates from '@/assets/BackOfficeAssets/question-templates.json'
export default {
    name: 'ComponentsCatalog', 
    components:{
        draggable, 
        'panel-legend':PanelLegend, 
        questionTemplates
    }, 
    computed:{ 
        components:{
            get(){
                return questionTemplates
            }, 
            set(){
                
            }
        },
        dragOptions(){
            return{
                group:{ name:'components',  pull:'clone', put:false }, 
                ghostClass: "ghost",
            }
        }

    }, 
    methods:{
        src(icon)
        {
            switch (icon) {
                case "essay":
                    return require('@/assets/BackOfficeAssets/essay.svg')
                case "multiple-choice":
                    return require('@/assets/BackOfficeAssets/multiple-choice.svg')
                case "venn-diagram":
                    return require('@/assets/BackOfficeAssets/venn-diagram.svg')
                default:
                    return require('@/assets/BackOfficeAssets/written-answer.svg')
            }
        }
    }
}
</script>

<style lang="less" scoped>
.custom-scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 200px);
}
</style>
