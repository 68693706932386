var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.component
    ? _c(
        "div",
        {
          staticClass: "assessment-header column",
          style: { order: 0 },
          on: { click: _vm.updateCurrentComponent }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.component.title[_vm.locale]))]),
          _c("div", { staticClass: "column" }, [
            _c("label", { attrs: { for: "text" } }, [
              _vm._v(_vm._s(_vm.$t("assessmentDirections")))
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.directions,
                  expression: "directions"
                }
              ],
              staticClass: "custom-scrollbar",
              attrs: {
                name: "text",
                placeholder: "Directions",
                rows: "5",
                cols: "30"
              },
              domProps: { value: _vm.directions },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.directions = $event.target.value
                }
              }
            }),
            _c(
              "div",
              {
                staticClass: "column",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.onButtonClick($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      class: [
                        { primary: _vm.typeOfQuestion === "multiple-choice" }
                      ],
                      attrs: { id: "multiple-choice" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("multipleChoice"))
                      )
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      class: [
                        { primary: _vm.typeOfQuestion === "venn-diagram" }
                      ],
                      attrs: { id: "venn-diagram" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("vennDiagramLongName"))
                      )
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      class: [{ primary: _vm.typeOfQuestion === "written" }],
                      attrs: { id: "written" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("writtenAnswer"))
                      )
                    ]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      class: [{ primary: _vm.typeOfQuestion === "essay" }],
                      attrs: { id: "essay" }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("essayLongName"))
                      )
                    ]
                  )
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.directionsByQuestionType,
                      expression: "directionsByQuestionType"
                    }
                  ],
                  staticClass: "custom-scrollbar",
                  attrs: { placeholder: "Directions", rows: "5", cols: "30" },
                  domProps: { value: _vm.directionsByQuestionType },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.directionsByQuestionType = $event.target.value
                    }
                  }
                })
              ]
            )
          ]),
          _c("span", { staticClass: "legend secondary centered" }, [
            _vm._v(_vm._s(_vm.$t("assessmentHeader")))
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }