var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-section row" },
    [
      _c(
        "div",
        { staticClass: "content-container column custom-scrollbar" },
        [
          _vm.assessmentHeader
            ? _c("assessment-header", {
                attrs: { component: _vm.assessmentHeader }
              })
            : _vm._e(),
          _vm._l(_vm.components, function(component, index) {
            return _c(component.type, {
              key: index,
              tag: "component",
              attrs: { component: component, count: index + 1 }
            })
          })
        ],
        2
      ),
      _c("properties-panel", {
        attrs: { "save-component": _vm.saveAssessment }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }