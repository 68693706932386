<template>
  <div class="content-section row">
    <div class="content-container column custom-scrollbar">
      <assessment-header
        v-if="assessmentHeader"
        :component="assessmentHeader"
      />
      <component
        v-for="(component, index) in components"
        :key="index"
        :is="component.type"
        :component="component"
        :count="index + 1"
      />
    </div>
    <properties-panel :save-component="saveAssessment" />
  </div>
</template>
<script>
import AssessmentHeader from "./AssessmentComponents/AssessmentHeader.vue";
import EssayComponent from "./AssessmentComponents/EssayComponent.vue";
import MultipleChoiceComponent from "./AssessmentComponents/MultipleChoiceComponent.vue";
import WrittenAnswerComponent from "./AssessmentComponents/WrittenAnswerComponent.vue";
import VennDiagramComponent from "./AssessmentComponents/VennDiagramComponent.vue";
import PropertiesPanel from "@/components/BackOffice/PropertiesPanel.vue";
export default {
  name: "AssessmentBuilderContentSection",
  components: {
    essay: EssayComponent,
    "multiple-choice": MultipleChoiceComponent,
    written: WrittenAnswerComponent,
    "venn-diagram": VennDiagramComponent,
    AssessmentHeader,
    PropertiesPanel,
  },
  computed: {
    components() {
      return this.$store.state.assessmentQuestions;
    },
    component() {
      return this.$store.state.currentBackOfficeComponent;
    },
    assessmentHeader() {
      return this.$store.state.assessmentHeader;
    },
  },
  methods: {
    saveAssessment() {
      this.updateAssessmentDirections();
      this.updateQuestions();
    },
    updateQuestions() {
      this.components.forEach((element) => {
        if (element.audio.id)
          this.setComponentPropertyValue(element, element.audio.id, "audioId");
        this.updateQuestion(element);

        if (element.type === "venn-diagram") {
          this.setComponentPropertyValue(
            element,
            element.primarySetImage.id,
            "primarySetImageId"
          );
          this.setComponentPropertyValue(
            element,
            element.secondarySetImage.id,
            "secondarySetImageId"
          );
          this.updateVennDiagramElements(element.vennDiagramElements);
        }

        if (element.type === "multiple-choice")
          element.choices.forEach((e) => {
            this.updateChoiceTextInQuestion(e);
          });
      });
    },
    updateAssessmentDirections() {
      this.$store.commit("storeAssessmentDirectionsAudioIds");
      this.setComponentPropertyValue(
        this.assessmentHeader,
        this.assessmentHeader.audio.id,
        "audioId"
      );
      this.$store.dispatch("updateAssessmentDirections", this.assessmentHeader);
    },
    updateChoiceTextInQuestion(choice) {
      this.$store.dispatch("updateChoiceTextInQuestion", choice);
    },
    updateQuestion(question) {
      this.$store.dispatch("updateQuestion", question);
    },
    setComponentPropertyValue(component, value, propertyName) {
      this.$store.commit("setBuilderComponentPropertyValue", {
        component: component,
        propertyName: propertyName,
        value: value,
      });
    },
    updateVennDiagramElements(elements) {
      elements.forEach((e) => {
        this.$store.dispatch("updateVennDiagramElement", e);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.content-container {
  margin-left: 45px;
  padding-bottom: 24px;
}
</style>