<template>
    <div class="assessment-builder builder">
        <component :is="currentSection"/>
    </div>
</template>
<script>
import BuilderMixin from '@/views/BackOffice/BuilderMixin.js'
import AssessmentBuilderContentSection from '@/components/BackOffice/AssessmentBuilder/AssessmentBuilderContentSection.vue'
import AssessmentBuilderLayoutSection from '@/components/BackOffice/AssessmentBuilder/AssessmentBuilderLayoutSection.vue'
export default {
    name: 'AssessmentBuilder', 
    mixins:[BuilderMixin], 
    components:{
        'content-section' : AssessmentBuilderContentSection, 
        'layout-section': AssessmentBuilderLayoutSection
    },
    created(){
        this.$store.commit('updateSwitchButtonDisplayStatus', true)
        this.$store.dispatch('loadAssessmentComponents', this.$route.params.lessonId)
                    .then(() => {
                        this.$store.commit("sortAssessmentBuilderComponents");
                    });
    }

}
</script>
<style lang="less" scoped>

</style>
