// MIXIN for builders that have a section for layout definition such as Biography Builder
// Lesson Builder, and Assessment Builder. 
import draggable from 'vuedraggable'
export default{
    components:{
        draggable
    },
    methods:{        
        onMove({relatedContext, draggedContext}) {
            const relatedElement = relatedContext.element;
            const draggedElement = draggedContext.element;
            return (
                (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            );
        }, 
    },
    computed: {
        dragOptions(){    
            return {
                animation: 0,
                group: "components",
                ghostClass: "ghost"
            }
        },
    }, 
    created(){
        this.$store.commit('updateSwitchButtonDisplayStatus', true)
        this.$store.commit('updateContentSectionState', false)
    }
}