<template>
  <div
    class="assessment-builder layout-component row"
    :style="{ height: component.height }"
  >
    <span class="count">{{ count }}</span>
    <span class="centered legend">{{ $t(component.text) }}</span>
    <img :class="component.icon" :src="placeholder" />
    <div class="component-controls flex-container">
      <img
        :src="arrowUpIcon"
        :style="{ opacity: arrowUpDisabledStyle }"
        @click="changeComponentOrder(component.order - 1)"
      />
      <img
        :src="arrowDownIcon"
        :style="{ opacity: arrowDownDisabledStyle }"
        @click="changeComponentOrder(component.order + 1)"
      />
      <span @click="deleteComponent(component)">{{ $t("delete") }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "AssessmentBuilderLayoutComponent",
  props: {
    component: Object,
    count: Number,
  },
  computed: {
    placeholder() {
      switch (this.component.icon) {
        case "essay":
          return require("@/assets/BackOfficeAssets/essay-card.svg");
        case "multiple-choice":
          return require("@/assets/BackOfficeAssets/multiple-choice-card.svg");
        case "written-answer":
          return require("@/assets/BackOfficeAssets/written-answer-card.svg");
        case "venn-diagram":
          return require("@/assets/BackOfficeAssets/venn-diagram-card.svg");
        default:
          return require("@/assets/BackOfficeAssets/written-answer-card.svg");
      }
    },
    arrowUpIcon() {
      return require("@/assets/icons/arrow_upward.svg");
    },
    arrowDownIcon() {
      return require("@/assets/icons/arrow_downward.svg");
    },
    arrowUpDisabledStyle() {
      if (this.component.type === "essay") return "0";
      return this.component.order === 1 ||
        this.components[this.component.order - 2].type !== this.component.type
        ? "0.3"
        : "1";
    },
    arrowDownDisabledStyle() {
      if (this.component.type === "essay") return "0";
      return this.component.order == this.components.length ||
        this.components[this.component.order].type !== this.component.type
        ? "0.3"
        : "1";
    },
    components() {
      return this.$store.state.assessmentQuestions;
    },
  },
  methods: {
    changeComponentOrder(newOrder) {
      if (
        newOrder === 0 ||
        newOrder > this.components.length ||
        this.components[newOrder - 1].type !== this.component.type
      )
        return;

      let partialOrder = this.getComponentPartialOrder(
        this.component.type,
        newOrder
      );

      this.$store
        .dispatch("updateQuestionComponentOrder", {
          ...this.component,
          order: partialOrder,
        })
        .then(() => {
          this.$store.commit("changeQuestionComponentOrder", {
            component: this.component,
            newOrder: newOrder,
          });
          this.$store.commit("sortAssessmentBuilderComponents");
        });
    },
    deleteComponent() {
      this.$store.commit("updateLoadingStatus", true);
      setTimeout(() => {
        this.$store
          .dispatch("removeQuestionFromAssessment", this.component)
          .then(() => {
            this.$store.commit("updateCurrentBackOfficeComponent", undefined);
            this.$store.commit("updateLoadingStatus", false);
          });
      }, 500);
    },
    getComponentPartialOrder(questionType, overallOrder) {
      let firstComponent = this.components.find(
        (elem) => elem.type == questionType
      );

      return firstComponent && firstComponent.order < overallOrder
        ? overallOrder - firstComponent.order + 1
        : 1;
    },
  },
};
</script>
<style lang="less" scoped>
.assessment-builder.layout-component {
  position: relative;
  margin: 8px auto;
  max-width: 704px;
  box-shadow: var(--primary-shadow);
  img {
    height: -webkit-fill-available;
    width: 704px;
    max-width: 704px;
    z-index: -1;
  }

  .component-controls.flex-container {
    width: 147px;
    z-index: 2;
    align-content: center;
    span {
      margin-left: auto;
    }
    img {
      height: 20px;
      width: 20px;
      margin-left: 8px;
      align-self: center;
      cursor: pointer;
    }
  }
  .count {
    margin-left: 24px;
  }
}
</style>
