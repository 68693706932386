var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "assessment-builder layout-component row",
      style: { height: _vm.component.height }
    },
    [
      _c("span", { staticClass: "count" }, [_vm._v(_vm._s(_vm.count))]),
      _c("span", { staticClass: "centered legend" }, [
        _vm._v(_vm._s(_vm.$t(_vm.component.text)))
      ]),
      _c("img", { class: _vm.component.icon, attrs: { src: _vm.placeholder } }),
      _c("div", { staticClass: "component-controls flex-container" }, [
        _c("img", {
          style: { opacity: _vm.arrowUpDisabledStyle },
          attrs: { src: _vm.arrowUpIcon },
          on: {
            click: function($event) {
              _vm.changeComponentOrder(_vm.component.order - 1)
            }
          }
        }),
        _c("img", {
          style: { opacity: _vm.arrowDownDisabledStyle },
          attrs: { src: _vm.arrowDownIcon },
          on: {
            click: function($event) {
              _vm.changeComponentOrder(_vm.component.order + 1)
            }
          }
        }),
        _c(
          "span",
          {
            on: {
              click: function($event) {
                _vm.deleteComponent(_vm.component)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("delete")))]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }