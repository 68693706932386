<template>
    <div class="assessment-header column" v-if="component" v-on:click="updateCurrentComponent" :style="{order: 0}">
        <span>{{component.title[locale]}}</span>
        <div class="column">
            <label for="text">{{$t('assessmentDirections')}}</label>
            <textarea  name="text" class="custom-scrollbar" placeholder="Directions" rows="5" cols="30"
                    v-model="directions"/>
            <div class="column" v-on:click.stop="onButtonClick">
                <div class="row">
                    <button id="multiple-choice" 
                            class="btn" 
                            :class="[{primary: typeOfQuestion === 'multiple-choice'}]">
                        {{$t("multipleChoice")}}</button>
                    <button id="venn-diagram" 
                            class="btn" 
                            :class="[{primary: typeOfQuestion === 'venn-diagram'}]">
                        {{$t("vennDiagramLongName")}}</button>
                    <button id="written" 
                            class="btn" 
                            :class="[{primary: typeOfQuestion === 'written'}]">
                        {{$t("writtenAnswer")}}</button>
                    <button id="essay" 
                            class="btn" 
                            :class="[{primary: typeOfQuestion === 'essay'}]">
                        {{$t("essayLongName")}}</button>
                </div>
                <textarea class="custom-scrollbar" placeholder="Directions" rows="5" cols="30"
                        v-model="directionsByQuestionType" />
            </div>
        </div>
        <span class="legend secondary centered">{{$t('assessmentHeader')}}</span>
    </div>
</template>
<script>
import BackOfficeComponentMixin from '@/components/BackOffice/Mixins/BackOfficeComponentMixin.js'
import PredefinedDirections from "@/assets/BackOfficeAssets/predefinedDirections.json";
import { setTimeout } from 'timers';

export default {
    name:'AssessmentHeader', 
    mixins:[BackOfficeComponentMixin],
    components:{
        PredefinedDirections
    },
    data(){
        return {
            typeOfQuestion: ""
        };
    },
    computed:{
        directions:{
            get(){
                return this.component.directions[this.locale]
            }, 
            set(value)
            {
                this.setComponentPropertyValue(value, 'directions', this.locale)
            }
        },
        directionsByQuestionType:{
            get(){
                return this.typeOfQuestion && this.question ? this.question.content[this.locale] : "";
            }, 
            set(value){
                this.$store.commit("updateQuestionDirection", {
                    type: this.typeOfQuestion,
                    language: this.locale,
                    value: value
                });
            }
        },
        question(){
            return this.questionsDirections[this.typeOfQuestion];
        },
        questionsDirections(){
            return this.component.questionDirections;
        }
    },
    methods:{
        onButtonClick({target}){
            if((!target.id || target.id == "") && target.type != "textarea")
                return;

            if(target.type != "textarea")
                this.typeOfQuestion = target.id;

            let _action = null;

            if(!this.question)
                _action = "addQuestionDirection";
            else if(!this.question.content[this.locale])
                _action = "updateQuestionDirection";

            if(_action)
                this.$store.commit(_action, {
                    type: this.typeOfQuestion,
                    language: this.locale,
                    value: PredefinedDirections[this.typeOfQuestion].direction[this.locale]
                });

            this.$store.commit('updateCurrentBackOfficeComponent', this.question);
        },
        setPredefinedGeneralDirections(){
            let _predefinedDirections = PredefinedDirections.general.direction[this.locale];

            if(!this.directions)
                this.setComponentPropertyValue(_predefinedDirections, "directions", this.locale);
        }
    },
    mounted(){
        setTimeout(() => {
            this.setPredefinedGeneralDirections();
            this.onButtonClick({ target: { id: "multiple-choice" } });
        }, 500);
    },
    watch:{
        locale(){
            this.setPredefinedGeneralDirections();

            if(!this.question.content[this.locale])
                this.$store.commit("updateQuestionDirection", {
                    type: this.typeOfQuestion,
                    language: this.locale,
                    value: PredefinedDirections[this.typeOfQuestion].direction[this.locale]
                });
        }
    }
}
</script>
<style lang="less">
.assessment-header{
	width: 704px;
	border-radius: 4px;
	background-color: #EFEFEF;
    box-shadow: var(--primary-shadow);
    padding: 0 24px;
    box-sizing: border-box;
    color: rgba(0,0,0,0.6);
    position: relative;
    margin-top: 16px;
    .legend{
        top: 0;
        margin-top: 13px;
        transform: translate(-50%,0);
    }
    span:first-child{
        margin-top: 65px;
        height: 24px;
        min-width: 125px;
        font-size: 19.64px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 24px;
    }
    textarea{
        max-height: 64px;
        max-width: 656px;
        resize: none;
    }
    .column{
        margin-top: 9px;

        > .row{
            justify-content: space-between;
            margin-top: 20px;
        }
    }

    label{
        height: 28px;
        width: 113px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
        text-transform: capitalize;
    }

    button{
        &:hover{
            cursor: pointer;
        }

        &:focus{
            outline: none;
        }
    }

    .row + textarea{
        margin-top: 5px;
        margin-bottom: 20px;
    }
}
</style>